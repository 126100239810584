import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Col, Row, Table } from 'react-bootstrap';

import '../../generic-page.scss';
import { Employee } from '../../../modules/cs/common/components/employee';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { Ship } from '../../../modules/cs/common/components/ship';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const CSBeginnerGuidePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_beginner.png"
            alt="Beginner guide"
          />
        </div>
        <div className="page-details">
          <h1>Beginner guide</h1>
          <h2>Everything you need to know as a new Counter Side player.</h2>
          <p>
            Last updated: <strong>12/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          The purpose of this guide is to familiarize new players with the
          various aspects of CounterSide. This guide will go over basic
          progression, the core gameplay loop, and address some frequently asked
          questions. This guide will not cover mechanics and game mode specific
          items, as they lie beyond the scope of this guide.
        </p>
        <p>
          If you are willing to reroll for an Awakened SSR before you start
          playing the game, it is worth doing. Also check out our reroll guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Who to reroll for"
            link="/counter-side/guides/reroll"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_reroll.png"
                alt="Who to reroll for"
              />
            }
          />
        </Row>
        <p>
          The table of contents has been included below for ease of navigation.
        </p>
        <h5>Table of Contents</h5>
        <ol>
          <li>Beginner Progression</li>
          <ol>
            <li>General expectations</li>
            <li>New Player Resources</li>
            <li>Early Direction</li>
            <ul>
              <li>Resource Challenge Stages</li>
              <li>Shadow Palaces</li>
              <li>Deep Conquest</li>
              <li>Dives</li>
              <li>Raids</li>
            </ul>
          </ol>
          <li>Core Gameplay Loop</li>
          <ol>
            <li>Basic resource management</li>
            <ul>
              <li>Eternium</li>
              <li>Quartz</li>
              <li>Employee Contracts</li>
              <li>Classified Employee Contracts</li>
              <li>Misc. Shop Currencies</li>
            </ul>
            <li>Competitive Game Modes</li>
            <ul>
              <ol>
                <li>Ranked Gauntlet (PVP)</li>
                <li>Danger Close (PVE)</li>
              </ol>
            </ul>
          </ol>
          <li>Frequently Asked Questions</li>
          <ol>
            <li>What do you do with dupes?</li>
            <li>Where do I farm gear?</li>
            <li>How do I improve gear?</li>
            <li>What do I do with bad gear?</li>
            <li>Should I farm Substream shops?</li>
            <li>How should I build my branches?</li>
            <li>Is Exclusive Equipment good?</li>
            <li>What characters are worth farming?</li>
          </ol>
        </ol>
        <SectionHeader title="1. Beginner Progression" />
        <h3>1.1 General Expectations</h3>
        <p>
          Before you get too deep into the game’s systems, there are some items
          you will want to keep in mind while you get through your early
          progression:
        </p>
        <h5>Endgame (PVE/ PVP) Specialization</h5>
        <p>
          Oftentimes, new players will adopt the mentality of “playing for PVE”
          or “playing for PVP”, and use this mindset to guide their
          decision-making. This is fine, but before picking a specialization
          it’s a good idea to understand what that actually entails.
        </p>
        <p>
          All players are clearing the same general PVE stages because all
          players farm for the same resources. The main difference lies in how
          players prioritize spending their resources. At a high level,
          CounterSide’s resource management is centered around its two main
          competitive game modes, Danger Close (PVE) and Ranked Battle (PVP).{' '}
        </p>
        <p>
          Before you get started on these game modes, there is a lot of
          foundational work that has to be done first. Account specialization
          will not do anything unless that stuff is done. CounterSide has a
          large cast of characters and the majority of the “meta” ones have
          great performance both in PVE and PVP. The competitive PVE/ PVP game
          modes should not influence your early game decision-making.{' '}
        </p>
        <p>
          Account specialization doesn’t truly start until you invest in your
          first operator and that won’t happen until several months into the
          game.
        </p>
        <h5>Gear = Progression</h5>
        <p>
          Almost all CounterSide units (with few exceptions) share the same gear
          presets. Instead of every character having a unique build, there are a
          few gear archetypes (tank gear, damage gear, support gear etc.) you
          shuffle around as needed. Character performance can be almost entirely
          attributed to gear.{' '}
        </p>
        <p>
          Your account progression can be measured by how many gear presets you
          have set up. Gear matters much more than characters. You will never be
          gated out of general PVE content because you lack a particular SSR,
          but you will periodically find yourself statchecked as a result of
          being undergeared.
        </p>
        <h5>Follow the TASKFORCE Plan</h5>
        <p>
          The TASKFORCE Plan covers the entirety of the new player experience.
          New players are heavily encouraged to use the TASKFORCE Plan missions
          as a starting point to determine your next steps.
        </p>
        <p>
          TASKFORCE missions are always available so you do not have to rush
          them. Just treat them as a guide that gets you to engage with the
          various features of the game at a pace that loosely follows account
          progression.
        </p>
        <h5>Use the In-Game Management Guide</h5>
        <p>
          The in-game Management Guide (referred to as the “Guide Book” in-game)
          can be accessed by clicking the (?) icon, located near the top left of
          your screen in most pages of the game.
        </p>
        <p>
          The Management Guide describes the basics of most mechanics and
          gamemodes in the game, so do refer to it whenever you are feeling
          lost.{' '}
        </p>
        <h3>1.2 New Player Resources</h3>
        <p>
          As part of the TASKFORCE Plan mission rewards, new players will
          receive (among other things) several selector coupons for various
          items. To set yourself up for success, it is heavily encouraged you
          use them as follows:
        </p>
        <h5>X6 New CEO Coupon [SSR]</h5>
        <StaticImage
          src="../../../images/counterside/generic/beg_1.webp"
          alt="Beginner guide"
        />
        <p>
          The New CEO Coupon [SSR] is a character selector that allows you to
          pick from a limited pool of SSR characters.{' '}
        </p>
        <p>
          If you have no personal preferences, the following characters listed
          below represent some of the strongest options in their respective
          roles and will remain relevant as you progress endgame content. If
          nothing else, you are <strong>heavily encouraged</strong> to reserve a
          selector for Yang Harim, arguably the strongest supporter in the game.
        </p>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Frontline</th>
              <th>Damage dealer</th>
              <th>Support</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="employee-container">
                  <Employee slug="kaci-bins" mode="icon" enablePopover />
                  <Employee slug="alex" mode="icon" enablePopover />
                  <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
                </div>
              </td>
              <td>
                <div className="employee-container">
                  <Employee slug="gaeun" mode="icon" enablePopover />
                  <Employee slug="xiao-lin" mode="icon" enablePopover />
                </div>
              </td>
              <td>
                <div className="employee-container">
                  <Employee slug="yang-harim" mode="icon" enablePopover />
                  <Employee slug="sky-layfield" mode="icon" enablePopover />
                  <Employee
                    slug="nanahara-chinatsu"
                    mode="icon"
                    enablePopover
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          These suggestions are made with reference to the rearmament system.
          Some characters (Kaci Bins, Alex, Nanahara Chinatsu) become
          unbelievably broken when you unlock their rearms. The rearmament
          system allows you to “upgrade” eligible characters and give them new
          kits. For more information, see the Prydwen Rearm Guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Rearm system"
            link="/counter-side/guides/rearm-system"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_rearm.png"
                alt="Rearm system"
              />
            }
          />
        </Row>
        <h5>Or here directly:</h5>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Frontline</th>
              <th>Damage dealer</th>
              <th>Support</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="employee-container">
                  <Employee
                    slug="hero-blossom-kaci"
                    mode="icon"
                    enablePopover
                  />
                  <Employee slug="breakbeat-alex" mode="icon" enablePopover />
                </div>
              </td>
              <td>
                <div className="employee-container">
                  <Employee slug="volume-up-gaeun" mode="icon" enablePopover />
                  <Employee slug="kestrel-xiao-lin" mode="icon" enablePopover />
                </div>
              </td>
              <td>
                <div className="employee-container">
                  <Employee slug="rearmed-chinatsu" mode="icon" enablePopover />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <h5>X1 New CEO Coupon [SSR] (Old)</h5>
        <p>
          A 20-day new player login event also gifts players with an SSR
          selector, but this one has an even more limited pool than those from
          the TASKFORCE Plan. You may want to consider using this selector for
          Gaeun/ Xiao Lin to free up a TASKFORCE Plan selector for someone else.
        </p>
        <div className="employee-container">
          <Employee slug="gaeun" mode="icon" enablePopover />
          <Employee slug="xiao-lin" mode="icon" enablePopover />
        </div>
        <h5>X1 T5 Replica Gear Set Option Selection Coupon</h5>
        <p>
          This gear selector is redeemable for a full T5 gear set with a set
          bonus and faction of your choosing. Only the{' '}
          <strong>T5 Replica Counter Gear Box [Skill Haste] is relevant</strong>
          . All other sets are terrible. This is because:
        </p>
        <ul>
          <li>
            In CounterSide, all buffs scale with target stats (e.g. healing
            skills heal a % of the target’s max HP). Support units only really
            care about skill haste because skill haste dictates cooldowns.
          </li>
          <li>
            T5 gear has lower substat values than T6/ T7 gear so it inevitably
            gets outpaced as you get better stuff. However, skill haste is not
            as affected as other (offensive/ defensive) substats so a T5 set is
            still usable.
          </li>
          <li>
            You will want a ton of skill haste sets for the myriad of supporters
            you bring in a team. Even when you complete a better set, the
            Replica stuff just gets passed down to the next supporter.
          </li>
        </ul>
        <h5>X1 New CEO Ship Selection Coupon [SSR]</h5>
        <StaticImage
          src="../../../images/counterside/generic/beg_2.webp"
          alt="Beginner guide"
        />
        <p>
          For general content, the only two relevant ships are Enterprise and
          New Ohio.
        </p>
        <div className="employee-container">
          <Ship unitId="26024" mode="icon" enablePopover />
          <Ship unitId="26026" mode="icon" enablePopover />
        </div>
        <p>For more information, see also:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Ship building order"
            link="/counter-side/guides/ship-building-order"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_shipbuild.png"
                alt="Ship building order"
              />
            }
          />
        </Row>
        <p>
          Of note, the SSR ship selector allows you to bypass unlock
          prerequisites. While realistically you will never find a need early on
          for the unlockable ships, this lets you skip some steps and acquire
          them very early on.
        </p>
        <h5>X1 My First Operator Recruitment Coupon [SSR]</h5>
        <p>
          None of the operators in this selector are Kim Hana. This means the
          selector is largely irrelevant to your progression.
        </p>
        <p>
          Of the operators available, <strong>Olivie</strong> is the only one
          that regularly sees use. Even if you never intend to play PVP, you
          would still take Olivie as the other operators are strictly worse than
          their alternatives. For more information, see also:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Operators and you"
            link="/counter-side/guides/operators-and-you"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_operators.png"
                alt="Operators and you"
              />
            }
          />
        </Row>
        <h5>X4 T6 Polymer Gear Set Option Selection Coupon [SSR]</h5>
        <StaticImage
          src="../../../images/counterside/generic/beg_3.webp"
          alt="Beginner guide"
        />
        <p>
          T6 polymer movements are flexible (relatively) easily accessible
          accessories that can be used to supplement tank gear and support gear.
          These selector coupons can be used to make:
        </p>
        <ul>
          <li>X2 HP Counter Polymer Movements</li>
          <li>X2 CDR Counter Polymer Movements</li>
        </ul>
        <p>
          Windbow’s gear guide is an excellent resource for understanding
          CounterSide’s gear systeming. New players are heavily encouraged to
          read it before committing any resources towards gear. You can find the
          guide{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1wrg8o2SPz59CC_oxBnMjd6SK3v41qVi66AhbsLuLpIg/edit#gid=0"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <h5>X4 T6 Sc. Gear Set Option Selection Coupon [SSR]</h5>
        <StaticImage
          src="../../../images/counterside/generic/beg_4.webp"
          alt="Beginner guide"
        />
        <p>
          Superconductive gear is used as filler pieces to complete offensive
          gear sets. You may use these coupons as you deem necessary.
        </p>
        <p>
          T6 Sc. gear selectors are usually used on cases because they are the
          only cases in the game which can have both Crit DMG% and Skill Haste
          substats at the same time, making them the only case in the game with
          two offensive substats. Sc. cases are useful as generic DPS sets on
          units that can still benefit from some Haste.
        </p>
        <p>
          If you don’t think your account would benefit from immediately
          redeeming these gear coupons, you may alternatively opt to hang onto
          them for half a year until you start seriously competing in Danger
          Close and need niche sets to squeeze out more damage against certain
          bosses.
        </p>
        <h3>1.3 Early Direction</h3>
        <p>Early on, your main goals should be:</p>
        <ul>
          <li>Progressing the Mainstream stages</li>
          <li>Progressing the Substream stages</li>
          <li>Completing the TASKFORCE missions</li>
        </ul>
        <p>
          While you level your characters and collect T6 (Maze/ Gordias) gear
          from the various shops, good short-term goals to work towards include:
        </p>
        <h5>Resource Challenge Stages</h5>
        <p>
          The rotating Resource Challenge stages form a core part of your
          dailies and is one of the main areas you’ll be dumping your Eternium,
          so you’ll want to get it figured out sooner than later.
        </p>
        <p>
          Of the Resource Challenge stages, the three most important ones are:
        </p>
        <ul>
          <li>Anastasia’s Supply Maintenance (Tuning Binary)</li>
          <li>Anastasia’s Supply Maintenance (Enhancement Module)</li>
          <li>Chloe’s Credit Challenge (Credit)</li>
        </ul>
        <p>
          The Daily Challenge Stages can be accessed from Operation &gt; Request
          &gt; Challenge. The schedule has been included below:
        </p>
        <StaticImage
          src="../../../images/counterside/generic/beg_5.webp"
          alt="Beginner guide"
        />
        <h5>Shadow Palace</h5>
        <p>
          Shadow Palace is a one-off game mode where you clear dungeons of
          increasing difficulty for material to craft Spectral and Phantom gear.
        </p>
        <ul>
          <li>
            1 Quantum Coords Crystal is generated daily, which can be
            accumulated up to a limit of 7.
          </li>
          <ul>
            <li>
              Quantum Coords Crystals are used to gain entry into the Shadow
              Palaces.
            </li>
          </ul>
          <li>Clearing the Shadow Palace will reward Shadow Fragments.</li>
          <ul>
            <li>
              Shadow Fragments can be used in the Shadow Palace shop to
              purchase:
            </li>
            <ul>
              <li>T6 Spectral Universal Molds used to craft Spectral gear.</li>
              <li>T6 Phantom Universal Molds used to craft Phantom gear.</li>
            </ul>
          </ul>
        </ul>
        <p>
          There is an instant skip function, so you don’t need to clear the
          Palaces more than once.
        </p>
        <p>
          Spectral and Phantom gear are designed to be transitional gear sets
          while you build your proper gear sets. Shadow Palace gear have far
          less set bonuses and substats in the RNG pool, making it an affordable
          way to get good gear that’s about on par with Maze/ Gordias.
        </p>
        <ul>
          <li>
            Spectral gear is considered a budget alternative to ASPD Maze.
            Spectral allows you to make a damage set with Anti-X DMG% and
            Anti-Ground DMG% substats without committing a full Maze set.
          </li>
          <li>
            Phantom gear is a cheap accessible source of tank gear pieces.
            Phantom gear is perfectly serviceable gear and can even be stronger
            than Gordias depending on the situation
          </li>
        </ul>
        <p>
          For more information on Spectral gear, see Windbow’s Gear Guide. You
          can find the guide{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1wrg8o2SPz59CC_oxBnMjd6SK3v41qVi66AhbsLuLpIg/edit#gid=0"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>
          For a complete rundown on the gamemode, check Prydwen’s Shadow Palace
          guides:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Shadow Palace overview"
            link="/counter-side/guides/shadow-palace"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_overview.png"
                alt="Shadow Palace"
              />
            }
          />
        </Row>
        <p>
          Shadow Palace can be accessed from Operation &gt; Growth &gt; Shadow
          Palaces.
        </p>
        <h5>Deep Conquest</h5>
        <p>
          Deep Conquest is a one-off game mode where you clear challenging and
          gimmicky stages for various rewards. Among other items:
        </p>
        <ul>
          <li>
            Challenge Act 2-20 rewards you with a Counter Gordias Crown and
            Movement
          </li>
          <li>
            Challenge Act 3-20 rewards you with a full set of Counter Maze gear
          </li>
          <li>
            Challenge Act 4-8 rewards you with Counter Challenger Hands and Case
          </li>
        </ul>
        <p>
          For when you inevitably get stuck on Challenge Act 3-15, you can
          reference:
        </p>
        <Row xs={1} xl={3} className="video-row">
          <Col>
            <YoutubeEmbed embedId="zG5iDtarm-c" />
          </Col>
        </Row>
        <p>
          Deep Conquest can be accessed from Operation &gt; Request &gt;
          Challenge &gt; Deep Conquest.
        </p>
        <p>
          For a guide for the Deep Conquest, check{' '}
          <a
            href="https://docs.google.com/document/d/1w8TFJm_BtYNQFNrmyTMBuKQKcxZwxqIJGDd9Upl1Ttw/edit"
            target="_blank"
            rel="noreferrer"
          >
            this document
          </a>
          .
        </p>
        <h5>Dives</h5>
        <p>
          Dives are a one-off game mode where you clear increasingly difficult
          stages for various rewards. Your Dive progress will have an impact on
          your long-term account progression.
        </p>
        <p>
          A summary has been provided to quickly go over the resources managed
          in this game mode. The resources have been bolded to make it easier to
          follow along:
        </p>
        <ul>
          <li>
            2 Exploration Permits are generated daily, which can be accumulated
            up to a limit of 10.
          </li>
          <ul>
            <li>
              Exploration Permits are used to advance your progress through
              Dives.
            </li>
            <li>
              Exploration Permits can be used on previous cleared Dives to
              perform Safety Mining.
            </li>
            <ul>
              <li>
                Safety Mining instantly clears the Dive to yield an amount of
                Eternium and Imaginary Cores.
              </li>
            </ul>
          </ul>
          <li>
            Your progress in Dives affects the level of the Dives that spawn
            from your Branches.
          </li>
          <ul>
            <li>
              At deeper depths, this also affects the enemy unit composition of
              the Dive.
            </li>
            <li>
              Branch Dives yield various resources, including Eternium,
              Imaginary Cores, and at higher levels, Gold Bullets.
            </li>
          </ul>
          <li>
            Imaginary Cores and Gold Bullets are used in the Dive shops to
            purchase various materials:
          </li>
          <ul>
            <li>
              Imaginary Cores can be used to purchase various ship materials and
              tuning binaries.
            </li>
            <li>
              Gold Bullets can be used to purchase a limited amount of Maze gear
              and one copy of Evolved-001.
            </li>
          </ul>
        </ul>
        <p>See also:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Dive - overview"
            link="/counter-side/guides/dive-introduction"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_diveintro.png"
                alt="Dive introduction"
              />
            }
          />
        </Row>
        <p>Dives can be accessed from World Map &gt; Dive.</p>
        <h5>Raid Comps</h5>
        <p>
          Raids are a seasonal co-op boss game mode where you form teams of 16
          characters to fight a raid boss. Basic raid mechanics to understand
          are:
        </p>
        <ul>
          <li>
            Raid is co-op in the sense that multiple players can accumulate
            damage against the same boss through their own independent runs.
          </li>
          <li>
            The main purpose of raid seasons is to complete the 40,000 point
            Plaque Exchange and collect milestone rewards.
          </li>
          <li>
            Raid bosses have a chance to spawn every time you claim a finished
            dispatch mission.{' '}
          </li>
          <li>
            You will get the reward for raids spawned from your World Map
            branches, even if you do not attack them.
          </li>
          <li>
            Your shared raids are accessible by players from your friend list
            and consortium. You are heavily encouraged to build out your friend
            list and join an active guild to make raids easier to manage.
          </li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/beg_6.webp"
          alt="Beginner guide"
        />
        <p>
          Plaque Exchange is your primary source of raid currency. The raids
          themselves give very little. The primary purpose of raids is to
          advance the Plaque Exchange. It is not worth clearing raids once you
          have completed the Plaque Exchange.
        </p>
        <p>
          The ratio of raid points to info spent is exactly the same no matter
          what raid level you play (i.e. a level 70 raid is equally as info
          efficient as a level 150 raid), so it does not matter what level of
          raid you attack early on in the game.
        </p>
        <p>
          The Plaque Exchange is a highly lucrative source of raid currency used
          to craft gear that will stay relevant long into the endgame. Raid gear
          can only be obtained in limited quantities each raid season.
        </p>
        <ul>
          <li>The Britra raid drops currency used to craft offensive gear:</li>
          <ul>
            <li>T7 Britra</li>
            <li>T6 Superconductive</li>
          </ul>
          <li>
            The Inhibitor raid drops currency used to craft defensive gear:
          </li>
          <ul>
            <li>T7 Inhibitor</li>
            <li>T6 Polymer</li>
          </ul>
          <li>
            The Kraken raid drops currency used to exchange for Britra/Inhibitor
            currency.
          </li>
        </ul>
        <p>
          Raid currency is obtained when you help defeat bosses and when you
          progress the seasonal Plaque Exchange. The game does not care if you
          deal 0 damage. You will still receive the full rewards for helping
          defeat the boss. This is referred to as “leeching”.
        </p>
        <p>
          While leeching raids is a perfectly acceptable way to progress raids,
          you will eventually want to set up a proper raid comp:
        </p>
        <ul>
          <li>
            Raid comps touch on most of the good PVE units; building a raid team
            means building up a solid roster of PVE damage dealers, buffers, and
            debuffers. This covers much of the groundwork needed to set yourself
            up for success in PVE.
          </li>
          <li>
            Accumulating the gear needed to equip a raid comp is a great way to
            plan and grasp gear progression in a more tangible way.
          </li>
          <li>
            Being able to one-shot raids grants you a lot of agency because it
            means you are no longer reliant on your peers for damage.
          </li>
        </ul>
        <p>
          Raids spawned in by friends and guildmates can be accessed from World
          Map &gt; Exploration Status &gt; Support Request.
        </p>
        <p>
          For more information on Raid gear (specifically Relic gear), see
          Windbow’s Gear Guide. You can find the guide{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1wrg8o2SPz59CC_oxBnMjd6SK3v41qVi66AhbsLuLpIg/edit#gid=0"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>See also:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Raids - mode overview"
            link="/counter-side/guides/raids"
            image={
              <StaticImage
                src="../../../images/counterside/categories/category_raids.png"
                alt="Raids"
              />
            }
          />
        </Row>
        <p>For detailed video guides, check Mage’s YouTube channel:</p>
        <Row xs={1} xl={3} className="video-row">
          <Col>
            <YoutubeEmbed embedId="sHvzLK-Ed0A" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="SVYmkSV11Eg " />
          </Col>
        </Row>
        <SectionHeader title="2. Core Gameplay Loop" />
        <h3>2.1 Basic Resource Management</h3>
        <p>
          CounterSide juggles around dozens of resources but the two most
          important are:
        </p>
        <ul>
          <li>
            <strong>Eternium</strong>: Eternium is CounterSide’s
            stamina-equivalent. Eternium is used to clear stages and farm
            materials.
          </li>
          <li>
            <strong>Quartz</strong>: Quartz is CounterSide’s premium currency.
            It is regularly used to purchase various items spread across the
            game’s shops.
          </li>
        </ul>
        <h5>2.1.1 Eternium</h5>
        <p>
          CounterSide dailies are fairly straight-forward and you can complete
          them in under 5 minutes, but the initial setup can be daunting with
          everything scattered everywhere.
        </p>
        <p>
          Try to reserve some Eternium every day to put towards clearing the few
          farmable stages that drop tuning binaries. Tuning binaries are used to
          fix gear substats. This is important because there are a ton of gear
          presets you will want to iron out.
        </p>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Priority Stages</th>
              <th>Resource</th>
              <th>Daily Limit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Supply Operation: Covert Ops 1-5</td>
              <td>Tuning Binaries</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Supply Operation: Covert Ops 1-6</td>
              <td>Tuning Binaries</td>
              <td>3</td>
            </tr>
          </tbody>
        </Table>
        <p>
          The game gives you the option of paying quartz to clear the stages
          past the daily limit. This is never worth doing.
        </p>
        <p>After tuning binaries, the next main Eternium resource sinks are:</p>
        <ul>
          <li>
            <strong>Enhancement Modules</strong>: Enhancement modules are used
            to raise gear level.
          </li>
          <li>
            <strong>Credits</strong>: Credits are used for pretty much
            everything.
          </li>
          <li>
            <strong>Unit Data</strong>: A ton of CounterSide’s characters (SSR,
            SR) can be farmed from Hard Stages.
          </li>
        </ul>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Optional Stages</th>
              <th>Resource</th>
              <th>Daily Limit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Resource Challenge: Anastasia’s Supply Maintenance 1-3</td>
              <td>Modules</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Resource Challenge: Chloe’s Credit Search 1-3</td>
              <td>Credits</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Substream: Hard Stages</td>
              <td>Unit Data</td>
              <td>10 per stage</td>
            </tr>
          </tbody>
        </Table>
        <p>
          Depending on what you’re working towards, some stages may be worth
          adding to your daily routine:
        </p>
        <ul>
          <li>
            Info: The game will provide you passive info income from various
            mission rewards, but you will periodically need a ton of info for
            certain activities.
          </li>
          <ul>
            <li>Leveling character skills</li>
            <li>Leveling operator side skill</li>
            <li>Grinding branch dives</li>
            <li>Completing raids</li>
          </ul>
          <li>
            Factor Binder: Factor binder is a crafting material used for
            Alternium Reactors.
          </li>
          <li>
            Gold Bullet: Gold Bullets are used (primarily) to purchase Maze gear
            from the Dive shop.
          </li>
          <li>
            Special Catalyst: APT &gt; S-APT and S-APT &gt; Fusion core
            conversion costs special catalyst. You will be periodically
            revisiting this stage when you inevitably run out.
          </li>
          <li>
            Dimension Trimming: Dimension Trimming is the endgame Eternium sink
            needed to craft T7 Relic gear. This will not be a priority for a
            long time, but it is worth mentioning.
          </li>
        </ul>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Other Considerations</th>
              <th>Resource</th>
              <th>Daily Limit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Dimension Trimming Stages</td>
              <td>Relic Gear Mats.</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Resource Challenge: Chloe’s Info Search 1-3</td>
              <td>Info</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Supply Operation: Covert Ops 1-1</td>
              <td>Info</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Supply Operation: Covert Ops 1-2</td>
              <td>Info</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Supply Operation: Covert Ops 1-7</td>
              <td>Factor Binder</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Supply Operation: Covert Ops 1-8</td>
              <td>Gold Bullet</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Supply Operation: Investigation Activities 1-7</td>
              <td>Special Catalyst</td>
              <td>Unlimited</td>
            </tr>
          </tbody>
        </Table>
        <h5>Training Simulations</h5>
        <p>On the topic of dailies, remember to do your simulations. </p>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Other Daily</th>
              <th>Resource</th>
              <th>Daily Limit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Simulation: Attack Training 1-6</td>
              <td>Appraisals</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Simulation: Defense Training 1-5</td>
              <td>Training Data</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Simulation: Anti-air Training 1-5</td>
              <td>APT/ S-APT Cores</td>
              <td>2*</td>
            </tr>
          </tbody>
        </Table>
        <p>
          *You are encouraged to buy one extra Anti-Air Training Simulation
          Ticket every day using quartz; you will need a ton of S-APT cores at
          every point in the game.
        </p>
        <h5>Other Notable Sources of Eternium</h5>
        <p>
          There are a number of ways to get more Eternium beyond what is
          naturally regenerated:
        </p>
        <ul>
          <li>Use Exploration Permits to perform Safety Mining</li>
          <li>Spend Info to clear Branch Dives</li>
          <li>Watch up to 5 Ads/ Day (Mobile Client Only)</li>
        </ul>
        <h5>2.1.2 Quartz</h5>
        <p>
          Contrasting the way many games handle their premium currency, you are
          expected to spend quartz fairly regularly on certain shop items to
          accelerate your progression. These are:
        </p>
        <p>
          <strong>Daily</strong>
        </p>
        <ul>
          <li>5 Daily Shop Refreshes</li>
          <ul>
            <li>
              “Today’s Deals” is a shop that refreshes daily. It offers various
              goods but most importantly you can buy Employee Contracts (blue
              tickets) for credits.
            </li>
            <li>
              The shop will randomly generate items sold for quartz and credits.
              No item worth quartz is worth buying.
            </li>
            <li>
              The shop can be accessed from Shop &gt; Shop List &gt; Exchange
              Center.
            </li>
          </ul>
          <li>1 Additional Anti-Air* Simulation Training Permit</li>
          <ul>
            <li>
              S-APT cores are one of the major resource bottlenecks in the game
              because you need them to limit break characters and craft fusion
              cores.
            </li>
            <li>
              While the Anti-Air Simulation provides the most value in the long
              run, there is nothing wrong with doing the other simulations if
              you need those materials.
            </li>
          </ul>
        </ul>
        <p>
          <strong>Weekly</strong>
        </p>
        <ul>
          <li>Classified Recruitment Quartz Package (Highest Priority)</li>
          <ul>
            <li>
              This is your primary means of accumulating Classified Recruitment
              Contracts for the ASSR gacha banners.
            </li>
            <li>
              This package can be accessed from Shop &gt; Shop List
              &gt;Permanent Package &gt; Classified Recruitment Package.
            </li>
          </ul>
        </ul>
        <p>
          <strong>Monthly</strong>
        </p>
        <ul>
          <li>Set Binary Package</li>
          <ul>
            <li>
              There is a monthly set binary package that gives 30 set binaries
              for 1000 quartz.{' '}
            </li>
            <li>
              Set binaries are the most coveted resource in the game because you
              need them to reroll gear set effects.
            </li>
            <li>
              This package can be accessed from Shop &gt; Shop List &gt;
              Exchange Center &gt; Convenience Goods.
            </li>
          </ul>
        </ul>
        <p>
          <strong>Other</strong>
        </p>
        <ul>
          <li>Buying gear preset slots</li>
          <ul>
            <li>
              You will inevitably run out of gear presets while you’re
              organizing your inventory. You are heavily encouraged to buy as
              many gear presets as you need, and then buy more.
            </li>
            <li>
              Gear presets are a massive quality of life feature that can be
              used to manage large quantities of gear, plan work-in-progress
              builds, and even swap gear within sets.
            </li>
          </ul>
          <li>Expanding inventory</li>
          <ul>
            <li>
              Even with frequent inventory management you will still inevitably
              run out of inventory space.
            </li>
            <li>
              You are encouraged to buy as many inventory slots as you deem
              necessary.
            </li>
          </ul>
        </ul>
        <h5>2.1.3 Employee Contracts</h5>
        <p>
          Employee Contracts are used for Normal Recruitment and Operator
          Recruitment banners.
        </p>
        <p>Normal Recruitment banners feature an SSR Employee rate-up.</p>
        <ul>
          <li>On rates:</li>
          <ul>
            <li>3.5% rate for an SSR</li>
            <li>1.0% rate for the rate up character</li>
          </ul>
          <li>On pity:</li>
          <ul>
            <li>
              On your 150th pull, you are guaranteed a copy of the rate up
              character.
            </li>
            <li>
              Normal Recruitment banner pity does not carry forward once the
              banner expires.
            </li>
          </ul>
        </ul>
        <p>Operator Recruitment banners feature an SSR Operator rate-up.</p>
        <ul>
          <li>On rates:</li>
          <ul>
            <li>2.0% rate for an SSR</li>
            <li>1.0% rate for the rate up operator</li>
          </ul>
          <li>On pity:</li>
          <ul>
            <li>
              On your 150th pull, you are guaranteed a copy of the rate up
              operator.
            </li>
            <li>
              Normal Recruitment banner pity does not carry forward once the
              banner expires.
            </li>
          </ul>
        </ul>
        <p>
          Early on, you can use Counter Cases to amass a ton of pulls very
          quickly. Counter Cases are collections of short stories that can be
          unlocked by paying Info.
        </p>
        <p>
          As of writing, 34 characters have Cases. For each of the 34
          characters, unlocking their second case awards the player 3 Employee
          Contracts for a total of 102 Employee Contracts
        </p>
        <p>
          Counter Cases can be accessed from Operation &gt; Substream &gt;
          Counter Case.
        </p>
        <h5>2.1.4 Classified Employee Contracts</h5>
        <p>
          Classified Employee Contracts are used for Classified Recruitment.
          Classified Recruitment banners feature an “Awakened” SSR (ASSR)
          Employee.
        </p>
        <ul>
          <li>On rates:</li>
          <ul>
            <li>3.5% rate for an SSR</li>
            <li>1.0% rate for the rate up ASSR</li>
            <li>
              There are no “spooks”. The only ASSR in the gacha pool is the rate
              up ASSR.
            </li>
          </ul>
          <li>On pity:</li>
          <ul>
            <li>
              On your 150th pull, you are guaranteed a copy of the rate up ASSR.
            </li>
            <li>
              Classified Recruitment banner pity carries forward once the banner
              expires.
            </li>
          </ul>
        </ul>
        <h5>2.1.5 Misc. Shop Currencies</h5>
        <p>
          On top of these main resources, CounterSide has a ton of currencies
          for various shops. Check the Prydwen Shops and Currencies Guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Shops and currencies"
            link="/counter-side/guides/shops-and-currencies"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_shops.png"
                alt="Shops and currencies"
              />
            }
          />
        </Row>
        <h3>2.2 Competitive Game Modes</h3>
        <p>
          CounterSide’s competitive game modes, Ranked Gauntlet (PVP), and
          Danger Close (PVE) are this game’s endgame. All of the game’s
          progression systems are to squeeze more value out of your characters
          in these game modes.
        </p>
        <h5>2.2.1 Ranked Gauntlet (PVP)</h5>
        <p>
          Ranked Gauntlet is CounterSide’s competitive PVP game mode. Ranked
          Gauntlet sees you assemble teams to face off against other players in
          real-time.
        </p>
        <p>
          The Bans/ Up system regularly shuffles the meta to keep PVP fresh by
          rotating character bans on a weekly schedule based on character usage
          rates and community votes.
        </p>
        <p>
          Rewards are distributed on a weekly basis depending on your division
          at the time of weekly reset. Weekly rewards are replaced by Seasonal
          rewards in the last week of the PVP season. Notably, these rewards
          include:
        </p>
        <ul>
          <li>Weekly rewards</li>
          <ul>
            <li>Eternium (increases with division)</li>
            <li>Quartz (increases with division)</li>
          </ul>
          <li>End-of-season rewards</li>
          <ul>
            <li>PVP-exclusive character skin (Diamond 5 and above)</li>
          </ul>
        </ul>
        <p>To get started, check Prydwen’s Intro to PVP guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Intro to RTA concepts"
            link="/counter-side/guides/rta-concepts"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_pvphistory.png"
                alt="Siege Playbook"
              />
            }
          />
        </Row>
        <h5>2.2.2 Danger Close (PVE)</h5>
        <p>
          Danger Close is CounterSide’s competitive PVE game mode. Danger Close
          sees you optimizing a team to kill one of many rotating bosses as
          efficiently as possible to maximize your score.
        </p>
        <p>
          Every Danger Close season will feature a boss, seasonal buff/ debuff
          modifiers, and a standard selection of self-debuffs that can be taken
          to increase your final score multiplier. If you’ve played Blue
          Archive, the mechanics are similar to Total Assault.
        </p>
        <p>
          Rewards are distributed at the end of every DC season depending on
          your leaderboard placement at the end of the season. Importantly, DC
          awards Danger Close Performance Points, a currency used to purchase
          items from the Danger Close shop.
        </p>
        <p>For more information, check Prydwen’s DC game mode overview:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Danger Close"
            link="/counter-side/guides/danger-close"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dangerclose_overview.png"
                alt="Danger Close Overview"
              />
            }
          />
        </Row>
        <p>
          To get an idea how these bosses play out, Mage is a content creator
          who regularly covers Danger Close in his videos.{' '}
        </p>
        <p>From Mage:</p>
        <blockquote>
          Danger close offers an intense gaming experience where players
          confront challenging bosses, each with unique mechanics designed to
          test their skills and strategy. The appeal of DC lies in the thrill of
          overcoming daunting odds on your own. As players strive to defeat
          bosses with speed and precision, the rewards they earn are valuable
          resources and rare gear. With its blend of strategic gameplay and
          satisfying rewards, DC provides a compelling and immersive challenge.
        </blockquote>
        <p>Check his CounterSide content here:</p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="iKF4y7lzqEk" />
          </Col>
        </Row>
        <SectionHeader title="3. Frequently Asked Questions" />
        <p>
          This section covers some basic FAQs you may encounter as you progress
          through the game.
        </p>
        <h5>3.1 What do you do with dupes?</h5>
        <p>
          Duplicate characters can be fed into the Tactical Update system, or
          used as a resource for the Rearmament System. To go over both briefly:
        </p>
        <p>
          <strong>Tactical Update System</strong>
        </p>
        <p>
          Tactical updates will improve character performance based on the
          number of duplicates fed into the character. The bonus is standard and
          consistent across all characters in the game.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/beginner_dupes0.webp"
          alt="Beginner guide"
        />
        <p>
          <strong>Rearmament System</strong>
        </p>
        <p>
          The rearmament system allows you to “upgrade” eligible characters and
          give them new kits. Many of the strongest characters in the game are
          rearms. In order to upgrade SSR characters into their rearmed
          versions, you will need Prime Rearmament Data.
        </p>
        <p>
          Prime Rearmament Data can be obtained by purchasing them from various
          shops, or by breaking down SSRs via Data Transmission.
        </p>
        <p>Prime Rearmament Data from Shops:</p>
        <ul>
          <li>
            The Business Card shop sells 20 Prime Rearmament Data monthly. You
            may want to consider purchasing them if you have an excess of
            business cards or if you are unwilling to sacrifice SSRs to convert
            into Prime Rearmament Data (more on this below).
          </li>
          <li>
            Certain Sidestream shops will sell rearm data at exorbitant prices.
            Players are heavily discouraged from grinding them out as the
            opportunity cost is far too great.
          </li>
        </ul>
        <p>Prime Rearmament Data from Data Transmission</p>
        <ul>
          <li>
            SSRs can be converted into Prime Rearmament Data. Players should
            consider converting duplicates of their low-performance SSRs into
            Prime Rearmament Data.
          </li>
          <li>Not all characters are worth the same amount of rearm data.</li>
          <ul>
            <li>SSRs obtained through through from the gacha</li>
            <ul>
              <li>These characters yield 20 Prime Rearmament Data</li>
              <li>These characters have been marked as Contract Obtained</li>
            </ul>
            <li>SSRs obtained outside the gacha </li>
            <ul>
              <li>These characters yield 2 Prime Rearmament Data</li>
              <li>
                This is a pittance, and frankly you might as well just use them
                as tactical updates
              </li>
            </ul>
          </ul>
          <li>
            While it should be obvious, it is worth emphasizing that converting
            an SSR character into Prime Rearmament Data will delete that copy of
            the SSR character.
          </li>
          <li>
            Data Transmission is accessible from Lab &gt; Data Transmission
            Room.
          </li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/beginner_dupes.webp"
          alt="Beginner guide"
        />
        <p>For more information, see the Prydwen Rearm Guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Rearm system"
            link="/counter-side/guides/rearm-system"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_rearm.png"
                alt="Rearm system"
              />
            }
          />
        </Row>
        <p>
          <strong>Prioritization</strong>
        </p>
        <p>To summarize:</p>
        <ul>
          <li>
            If SR or below, use for tactical updates on a single copy. The rest
            can be dismissed for Business Cards.
          </li>
          <li>If SSR, save to transmit into rearm data.</li>
          <ul>
            <li>
              If you already have all the rearms you desire you can use for
              Tactical Updates.
            </li>
            <li>
              If the unit doesn’t have “Contract Obtained” (received from
              gacha), it will provide almost no rearm data, just use it for
              Tactical Update.
            </li>
          </ul>
          <li>
            If ASSR, use your own judgment. Rearms are very valuable but ASSR
            dupes are extremely rare and it can feel wasteful to just transmit
            them.
          </li>
        </ul>
        <h5>3.2 Where do I farm gear?</h5>
        <p>
          CounterSide is unique in that some of the strongest gear in the game,
          Maze and Gordias gear, are accessible very early on but cannot be
          farmed*. Maze/ Gordias gears are available in limited quantities from
          a variety of sources. Instead of farming more of it, you will be
          farming tuning binaries to iron out the sets and substats.
        </p>
        <p>
          Technically, you can obtain random Maze pieces through Dimension
          Trimming but it’s less of a reward and more of a consolation prize for
          how long you’ve been trapped there.
        </p>
        <p>
          To supplement your Maze/ Gordias pieces, you will be obtaining good
          endgame-relevant gear from:
        </p>
        <ul>
          <li>
            <strong>Raids</strong> operate on a seasonal basis, providing tokens
            that can be used to craft gear.
          </li>
          <ul>
            <li>Britra raids drop currency used to craft generic DPS gear.</li>
            <li>Inhibitor raids drop currency used to craft tank gear.</li>
            <li>
              For more information, check the relevant section under 1.3 Early
              Direction.
            </li>
          </ul>
          <li>
            <strong>Shadow Palace</strong> provides currency that can be
            exchanged for binaries and unique gear sets.
          </li>
          <ul>
            <li>
              Shadow Palace gear can be used as a strong, cheap alternative gear
              set on many units.
            </li>
            <li>
              For more information, check the relevant section under 1.3 Early
              Direction.
            </li>
          </ul>
          <li>
            <strong>Dimension Trimming</strong> is an endgame mode and a way to
            farm Relic gear.{' '}
          </li>
          <ul>
            <li>
              Due to the high cost of uncertain rewards, it is recommended that
              you avoid Dimension Trimming until you have your basic gear sets
              completed.
            </li>
            <li>
              For more information, check the relevant sections under{' '}
              <a
                href="https://docs.google.com/spreadsheets/d/1wrg8o2SPz59CC_oxBnMjd6SK3v41qVi66AhbsLuLpIg/edit#gid=1415610240"
                target="_blank"
                rel="noreferrer"
              >
                Windbow’s Gear Guide
              </a>
              .
            </li>
          </ul>
        </ul>
        <h5>3.3 How do I improve gear?</h5>
        <p>
          The most important thing about gear is the substat. Substats are
          arguably more important than set bonuses* because they come in higher
          values. The vast majority of a gear’s performance comes from the
          substat. Tuning binaries are used to adjust substats on gear:
        </p>
        <ul>
          <li>
            Tuning binaries can increase substat values up to their maximum.
          </li>
          <li>
            Tuning binaries can be used to reroll substats endlessly until you
            get what you want.
          </li>
        </ul>
        <p>
          With the exception of the CDR set, which provides ~3 substats worth of
          skill haste and certain sets (Antagonist, Replica Maze, Initiative)
          come as a completed set.
        </p>
        <p>Tuning binaries can be farmed from:</p>
        <ul>
          <li>Covert Ops 1-5</li>
          <li>Covert Ops 1-6</li>
          <li>Anastasia’s Supply Maintenance (every other day)</li>
          <li>Various shops</li>
        </ul>
        <p>
          With the exception of Shadow Palace gear, substats on T6 gear can be
          further increased by upgrading the T6 piece to T7.
        </p>
        <ol>
          <li>
            Enhancement Modules or other gear can be fed into a gear piece to
            increase its level.
          </li>
          <li>
            At +10, T6 gear can be upgraded to T7 from the Upgrade Console.
          </li>
          <li>The Upgrade Console is accessible from Workshop &gt; Upgrade</li>
        </ol>
        <p>
          To avoid overcomplicating this section, only the relevant aspects of
          gear have been explained. For more information on gearing, check{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1wrg8o2SPz59CC_oxBnMjd6SK3v41qVi66AhbsLuLpIg/edit#gid=1415610240"
            target="_blank"
            rel="noreferrer"
          >
            Windbow’s Gear Guide
          </a>{' '}
          - Gear 101 - Part 6.{' '}
        </p>
        <h5>3.4 What do I do with bad gear?</h5>
        <p>
          It is important to keep in mind you’re expected to reroll the sets/
          subs on your gear pieces, so a piece won’t be bad purely because it
          didn’t start with what you want.{' '}
          <strong>
            Do not throw away your limited gear. You will not be getting more.
          </strong>
        </p>
        <p>
          However, some gear may not be worth spending set binaries on, and some
          gear is just irredeemably bad. To note some:
        </p>
        <ul>
          <li>
            T5/ T6 raid gear can be dismantled to get back some crafting tokens.
          </li>
          <li>
            T7 Relic gear with worthless latents can be fed into other gear as
            gear EXP.
          </li>
          <li>
            T3 Initiative sets that aren’t CDR sets can be fed into other gear
            as gear EXP.
          </li>
        </ul>
        <h5>3.5 Should I farm Substream shops?</h5>
        <p>
          As you farm Unit Data for characters from Substream hard stages, you
          will also earn currency specific to that Substream which can then be
          spent in its Substream shop.
        </p>
        <p>
          Substream shops are extremely Eternium-inefficient. You would not go
          out of your way to farm the Substream shop even when there is an
          ongoing currency drop rate up event for that Substream. They should be
          viewed as a small bonus when farming Unit Data.
        </p>
        <p>
          That said, there are some items exclusive to Substream shops worth
          noting. Whether you want to buy them or not is a personal judgment
          call:
        </p>
        <ul>
          <li>Pure Possibilities</li>
          <li>Lobby Backgrounds</li>
        </ul>
        <p>For further information on shops and currencies: </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Shops and currencies"
            link="/counter-side/guides/shops-and-currencies"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_shops.png"
                alt="Shops and currencies"
              />
            }
          />
        </Row>
        <h5>3.6 How should I build my branches?</h5>
        <p>
          As you complete dispatches from the World Map, your branches will
          level up. To make the most of your branch, you will want to prioritize
          building/ leveling in this order:
        </p>
        <ol>
          <li>
            Future Strategy Department: Reduces dispatch duration by up to 20%.
          </li>
          <li>
            PR Department: Increases the chance of high-class dispatch
            appearance by up to 30%.
          </li>
          <li>
            Spatial Coordinates Fixator: Increases the chance of Dives spawning
            by up to 40%.
          </li>
        </ol>
        <p>Some additional notes:</p>
        <ul>
          <li>
            Great Success rate is capped at 70%. Dispatch success rate is
            dependent on the level of your branch manager. In the vast majority
            of situations you will hit 70% without the Command Center. The
            Command Center is needed once you hit Branch level 10 to maintain
            70% for S-rank missions.
          </li>
          <li>
            You can delete prerequisite facilities once you’re done with them.
          </li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/beginner_faci.webp"
          alt="Beginner guide"
        />
        <h5>3.7 Is Exclusive Equipment good?</h5>
        <p>
          The vast majority of Exclusive Equipment (EE) is completely worthless.
          EEs pull their substats from the same general pool as other gear,
          meaning (with exceedingly few exceptions) they offer nothing special.
          They frequently come with a locked substat but more often than not it
          won’t be an improvement over the alternatives.
        </p>
        <p>
          Certain EEs will have unique main stats. Most of them are useless, but
          this makes some EEs so good they define the character’s performance.
          You would prioritize using these gear pieces even if they break the
          set bonus. These are:
        </p>
        <div className="employee-container">
          <Employee slug="xiao-lin" mode="icon" enablePopover />
          <Employee slug="kim-sobin" mode="icon" enablePopover />
          <Employee slug="titan" mode="icon" enablePopover />
        </div>
        <h5>3.8 What characters are worth farming?</h5>
        <p>
          Recalling 2.1 Basic Resource Management, Substream Hard Stages can be
          farmed for unit shards. Shards can be redeemed to obtain the full
          character from the Scout menu.
        </p>
        <p>The scout menu is accessible from the CEO's Office &gt; Scout.</p>
        <p>
          When starting out, you may want to prioritize farming unit data for
          the following:
        </p>
        <div className="employee-container">
          <Employee slug="maria-antonov" mode="icon" enablePopover />
          <Employee slug="warden" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="dash" mode="icon" enablePopover />
          <Employee slug="rivet" mode="icon" enablePopover />
          <Employee slug="tarrasque" mode="icon" enablePopover />
        </div>
        <p>
          Of these units, Rivet and Warden should be your top priority, because
          they can only be obtained from farming and are very versatile units.
          Notably, Rivet gets an extremely broken rearm that turns her into one
          of the strongest rangers in the game.
        </p>
        <p>
          Dash can also only be obtained from farming, but she is arguably lower
          priority than Rivet and Warden because she is only good in raids.{' '}
        </p>
        <p>
          If you are strapped for Eternium early on, you can skip farming for
          Maria Antonov, Admin. Rifleman, and Tarrasque. SR units can be
          obtained from gacha and you will naturally accumulate a ton of copies
          as you play the game.{' '}
        </p>
        <p>
          Certain characters (denoted by the asterisk) can only be obtained by
          farming their unit data.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/beginner_stream.webp"
          alt="Beginner guide"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default CSBeginnerGuidePage;

export const Head: React.FC = () => (
  <Seo
    title="Beginner guide | Counter Side | Prydwen Institute"
    description="Everything you need to know as a new Counter Side player."
    game="cs"
  />
);
